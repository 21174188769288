// 制作流程
<template>
  <div class="mackProcess">
    <backtitle />
    <div class="tabs">
      <div
        v-for="(item, index) in tabsTitles"
        :key="index"
        @click="showMain = index"
        :class="{activeClass:showMain===index}"
      >
      <div class="reg"></div>
      <div class="tipsImg" v-if="item.showTips">
        <img :src="successImg" v-if="item.pass" />
        <img :src="errorImg"  v-if="!item.pass"/>
      </div>
        {{ item.label }}
      </div>
    </div>
    <div class="content">
      <!-- 融资信息 -->
      <financing-info @errMsg='errMsg' v-show="showMain === 0" :tabsTitles='tabsTitles' :showMain='showMain' :financingData='financingData' ref="financingInfo"></financing-info>
      <!-- 项目合同信息 -->
      <project-contract-info
        v-show="showMain === 1"
        :finContractsData="finContractsData"
        :tabsTitles='tabsTitles' :showMain='showMain'
        ref='projectContractInfo'
        @errMsg='errMsg'
      ></project-contract-info>
      <!-- 企业信息 -->
      <enterprise-info  @errMsg='errMsg' v-show="showMain === 2" :tabsTitles='tabsTitles' :showMain='showMain' :enterpriseAllData='enterpriseAllData' ref="enterpriseInfo"></enterprise-info>
      <!-- 闭环信息 -->
      <closed-loop-info  ref='closeLoopInfo' @errMsg='errMsg' v-show="showMain === 3" :tabsTitles='tabsTitles' :showMain='showMain' :closedData='closedData' :businessId='businessId' :postloanManagementMeasuresVO='financingData.examineDetails.postloanManagementMeasuresVO' :fileInfos='financingData.examineDetails'></closed-loop-info>
    </div>
    <!-- 底部提交按钮 -->
    <div class="footer">

      <base-button label="关 闭" type="default" @click="close"></base-button>
    </div>
  </div>
</template>
<script>
import { dueDiligenceApi, contractDdApi } from '@/api/businessApi'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import Backtitle from '../components/backtitle.vue'
import FinancingInfo from './process/financing-info.vue'
import ProjectContractInfo from './process/project-contract-info.vue'
import EnterpriseInfo from './process/enterprise-info.vue'
import ClosedLoopInfo from './process/closed-loop-info.vue'
// import { financeAdmittanceApi } from '@/api/financeAdmittanceApi'
// import { contactApi } from '@/api/contactApi'

import Storage from '@/utils/storage'
export default {
  components: {

    Backtitle,
    FinancingInfo,
    ProjectContractInfo,
    EnterpriseInfo,
    ClosedLoopInfo,
    BaseButton
  },
  data () {
    return {
      successImg: require('@/assets/success.png'),
      errorImg: require('@/assets/error.png'),
      businessId: '', // 业务id
      tabsTitles: [{ label: '融资信息', pass: true, showTips: false }, { label: '项目合同信息', pass: true, showTips: false }, { label: '企业信息', pass: true, showTips: false }, { label: '闭环信息', pass: true, showTips: false }],
      showMain: 0,
      financingData: { // 总的融资信息
        financingPlanData: {}, // 融资方案信息
        examineDetails: { // 尽调详情数据
          actualControllerInterviewVO: { actualControllerInterview: '', fileInfos: [] },
          companyProfileVO: { companyProfile: '', fileInfos: [] },
          businessStateVO: { businessState: '', fileInfos: [] },
          gemdaleCooperationVO: { gemdaleCooperation: '', fileInfos: [] },
          marketEvaluationVO: { marketEvaluation: '', fileInfos: [] },
          postloanManagementMeasuresVO: {
            fileInfos: [],
            postloanManagementMeasures: ''
          }// 闭环信息贷后管理措施
        }, // 尽调详情数据
        guaranteeStateData: [], // 担保动态数据
        investigationSituationData: [] // 尽调情况表格数据
      }, // 融资方案信息
      finContractsData: [{ contractName: '', ddProjectFundingPlanVOs: [], ddProjectCommentVOs: [{ finFileInfos: [] }] }], // 项目合同信息

      closedData: {

        ddCustodialAccountInfoVOs: [], // 监管账户系统锁定
        ddSendingNoticesAddressVOs: [] // 通知书寄送列表
      }, // 闭环信息
      enterpriseAllData: { // 总的企业信息
        financeInfo: [], // 财务信息
        assetsInfo: [], // 资产信息
        outAssetsInfo: [], // 外部地产情况
        liabilitiesInfo: [], // 负债情况
        litigation: [], // 诉讼信息
        examineDetails: { // 尽调详情数据
          creditInfoVO: { creditInfo: '', fileInfos: [] },
          financialInfoVO: { financialAnalysisInfo: '', fileInfos: [] },
          assetInfoVO: { assetInfo: '', fileInfos: [] },
          extEstateInfoVO: { extEstateInfo: '', fileInfos: [] },
          // debtInfoVO: { debtInfo: '', fileInfos: [] },
          litigationInfoVO: { litigationInfoComment: '', fileInfos: [] }
        }
      }, // 所有的企业信息数据
      examineDetails: {}, // 尽调详情数据
      personalData: [], // 个人担保数据
      enterpriseData: [] // 企业担保数据
    }
  },
  computed: {
    api () {
      return dueDiligenceApi
    }
  },
  watch: {
    businessId (val) {
      if (val) {
        this.getGuaranteeList()
        this.getExamineInfoList()
      }
    },
    showMain (val) {
      if (val === 1) {
        document.getElementsByClassName('content')[0].setAttribute('class', 'contentScroll')
      } else {
        if (document.getElementsByClassName('contentScroll').length > 0) {
          document.getElementsByClassName('contentScroll')[0].setAttribute('class', 'content')
        }
      }
    }
  },
  methods: {
    // 记录数据验证信息
    errMsg (index, key) {
      console.log(index, key, '222')
      this.$set(this.tabsTitles[index], 'pass', key)
      this.$set(this.tabsTitles[index], 'showTips', true)
    },
    // 查询尽调监管账户信息 锁定 及通知书寄送信息
    getDdAccountInfo () {
      this.api.getDdAccountInfo({ businessId: this.businessId, enterpriseId: Storage.getLocal('contractInfo').gysId }).then(res => {
        console.log(res.data, '监管账户信息 锁定 及通知书寄送信息')
        this.closedData = res.data
      })
    },
    // 获取所有单独信息详情
    // 查询合同信息
    getDdFinContracts () {
      contractDdApi.getDdFinContracts({ businessId: this.businessId, enterpriseId: Storage.getLocal('contractInfo').gysId }).then(res => {
        this.finContractsData = res.data
        // this.finContractsData[0].chose = true
        this.$set(this.finContractsData[0], 'chose', true)
        this.finContractsData.forEach((item, index) => {
          if (index !== 0) {
            this.$set(this.finContractsData[index], 'chose', false)
          }
        })
        console.log(this.finContractsData, 'finContractsData')
      })
    },
    // 获取融资方案信息
    getFinPlan () {
      dueDiligenceApi
        .getFinPlan({ businessId: this.businessId })
        .then((res) => {
          if (res.data) {
            res.data.getCustomerMode = 'DAY'
            if (res.data.finRepayPlans && res.data.finRepayPlans.length > 0) {
              res.data.finRepayPlans.forEach(item => {
                this.$set(item, 'repayContractIdList', item.repayContractId.split(','))
              })
            }
            this.financingData.financingPlanData = res.data
            console.log(this.financingData.financingPlanData, '00000')
          }
        })
    },
    // 获取担保动态数据
    getGuaranteeList () {
      dueDiligenceApi
        .getGuaranteeList({ businessId: this.$route.query.businessId, enterpriseId: Storage.getLocal('contractInfo').gysId })
        .then((res) => {
          console.log(res.data, '担保动态数据')
          if (res.data) {
            this.financingData.guaranteeStateData = res.data ? res.data : []
          }
        })
    },
    // 获取尽调详情表格数据
    getExamineInfoList () {
      dueDiligenceApi
        .getExamineInfoList({ businessId: this.businessId, enterpriseId: Storage.getLocal('contractInfo').gysId })
        .then((res) => {
          if (res.data) {
            console.log(res.data, '获取尽调详情表格数据')
            this.financingData.investigationSituationData = res.data ? res.data : []
          }
        })
    },
    // 融资方案信息修改
    changeFinancingData (data) {
      console.log(data)
    },
    // 获取尽调所有单独信息
    getDetail () {
      dueDiligenceApi.getDetail({ businessId: this.businessId, enterpriseId: Storage.getLocal('contractInfo').gysId }).then(res => {
        if (res.data.keyId) {
          this.financingData.examineDetails = res.data
          this.financingData.examineDetails.companyProfileVO = res.data.companyProfileVO
          this.enterpriseAllData.examineDetails = res.data
        } else {
          this.financingData.examineDetails.companyProfileVO = res.data.companyProfileVO
        }
      })
    },
    // 获取 企业信息 财务信息表格数据
    getFinancialanalysisList () {
      dueDiligenceApi.getFinancialanalysisList({ businessId: this.businessId, enterpriseId: Storage.getLocal('contractInfo').gysId }).then(res => {
        if (res.data) {
          console.log(res.data, 'res.data')
          this.enterpriseAllData.financeInfo = res.data
        }
      })
    },
    // 获取 企业信息 资产信息表格数据
    getAssetinfoList () {
      dueDiligenceApi.getAssetinfoList({ businessId: this.businessId, enterpriseId: Storage.getLocal('contractInfo').gysId }).then(res => {
        if (res.data) {
          this.enterpriseAllData.assetsInfo = res.data
        }
      })
    },
    // 获取 企业信息 外部地产表格数据
    getExtestateinfoList () {
      dueDiligenceApi.getExtestateinfoList({ businessId: this.businessId, enterpriseId: Storage.getLocal('contractInfo').gysId }).then(res => {
        if (res.data) {
          this.enterpriseAllData.outAssetsInfo = res.data
        }
        console.log(res.data, '外部地产表格数据')
      })
    },
    // 获取 企业信息 企业负债信息
    getDebtinfoList () {
      dueDiligenceApi.getDebtinfoList({ businessId: this.businessId, enterpriseId: Storage.getLocal('contractInfo').gysId }).then(res => {
        if (res.data) {
          this.enterpriseAllData.liabilitiesInfo = res.data
        }
        console.log(res.data, '企业负债信息')
      })
    },
    // 获取 企业信息 企业诉讼信息
    getLitigationinfoList () {
      dueDiligenceApi.getLitigationinfoList({ businessId: this.businessId, enterpriseId: Storage.getLocal('contractInfo').gysId }).then(res => {
        if (res.data) {
          this.enterpriseAllData.litigation = res.data
        }
        console.log(res.data, '企业诉讼信息')
      })
    },
    // 保存&提交 所有尽调详情信息》单个文本域及上传文件内容
    async saveExamineDetails (type) {
      console.log(this.financingData.examineDetails, this.enterpriseAllData.examineDetails)
      const data = { ...this.financingData.examineDetails, ...this.enterpriseAllData.examineDetails, businessId: this.businessId }
      data.ActualControllerInterviewDTO = this.financingData.examineDetails.actualControllerInterviewVO
      data.BusinessStateDTO = this.financingData.examineDetails.businessStateVO
      data.CompanyProfileDTO = this.financingData.examineDetails.companyProfileVO
      data.GemdaleCooperationDTO = this.financingData.examineDetails.gemdaleCooperationVO
      data.MarketEvaluationDTO = this.financingData.examineDetails.marketEvaluationVO
      data.PostLoanManageDTO = this.financingData.examineDetails.postLoanManageVO
      data.postloanManagementMeasuresDTO = this.financingData.examineDetails.postloanManagementMeasuresVO
      data.fileId = this.financingData.examineDetails.fileId
      data.fileName = this.financingData.examineDetails.fileName
      // 企业信息中的
      data.CreditInfoDTO = this.enterpriseAllData.examineDetails.creditInfoVO
      data.financialInfoDTO = this.enterpriseAllData.examineDetails.financialInfoVO
      data.assetInfoDTO = this.enterpriseAllData.examineDetails.assetInfoVO
      data.extEstateInfoDTO = this.enterpriseAllData.examineDetails.extEstateInfoVO
      // data.debtInfoDTO = this.enterpriseAllData.examineDetails.debtInfoVO
      data.litigationInfoDTO = this.enterpriseAllData.examineDetails.litigationInfoVO
      let res = {}
      if (type === 'save') {
        res = await dueDiligenceApi.saveDetails(data)
      } else if (type === 'submit') {
        res = await dueDiligenceApi.submitDetails(data)
      }
      console.log(res.data)
    },

    async saveData (type) {
      // 融资信息 保存&提交 所有内容
      if (this.$refs.financingInfo.saveAll(type)) {

      }
      // 项目合同信息提交保存
      if (this.$refs.projectContractInfo.saveAll(type)) {

      }
      // 企业信息 提交&保存 所有内容
      if (this.$refs.enterpriseInfo.saveAll(type)) {

      }
      if (this.$refs.closeLoopInfo.saveAll(type)) {

      }
      // 融资 基础信息
      await this.saveExamineDetails(type)
      // 融资信息 保存&提交 所有内容
      // if (this.$refs.financingInfo.saveAll(type)) {
      //   return false
      // }
      // // 企业信息 提交&保存 所有内容
      // if (this.$refs.enterpriseInfo.saveAll(type)) {
      //   return false
      // }
    },
    close () {
      this.$router.back()
    }
  },
  mounted () {
    this.getGuaranteeList()
    this.getExamineInfoList()
    this.getDetail()
    this.getFinPlan()
    this.getDdFinContracts()
    this.getDdAccountInfo()
    this.getAssetinfoList()
    this.getExtestateinfoList()
    this.getDebtinfoList()
    this.getLitigationinfoList()
    this.getFinancialanalysisList()
  },
  created () {
    this.businessId = Storage.getLocal('contractInfo').businessId
  }
}
</script>
<style lang="scss" scoped>
.contentScroll{
  background: #fff;
  height: calc(100% - 125px);
  overflow-y:visible !important ;
}
.mackProcess {
  height: 100%;
  background: #f9f9f6;
  box-sizing: border-box;
  overflow: hidden;
}
.tabs {
  width: 600px;
  height: 40px;
  display: flex;
  justify-content: space-around;

  // margin: 20px;
  .activeClass{
    background: #EBF1FF !important;
    font-weight: 600;
  }
  > div {
    flex: 1;
    box-sizing: border-box;
    // border-bottom: 3px solid red;
    height: 100%;
    width: 120px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    background: #FFFFFF;
    color: #2D363E;
    position: relative;
    .tipsImg{
      position: absolute;
      top:3px;
      left: 2px;
      width: 12px;
      height: 12px;
      img{
        width: 100%;
      }
    }
    .reg{
      height: 0;
      width: 0;
      border-top:40px solid #f9f9f6;
      border-left:22px solid transparent;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
.content {
  background: #fff;
  height: calc(100% - 140px);
  overflow-y: scroll;
}
.footer {
  text-align: center;
  margin: 0px 0 10px;
  height: 60px;
  line-height: 60px;
  box-shadow: 0px 0px 13px 2px rgba(#4A7CF0, 0.2);
  background: #ffffff;
}
</style>
