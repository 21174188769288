// 资产信息
<template>
  <div class="assetsInfo">

    <base-table
      :columns="assetsInfolumn"
      :tableAttrs="{
        data: assetsInfoData,
        stripe: true,
      }"
      :isCaculateHeight="false"
      :showPage="false"
    >
      <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>

      <!-- 资产类型 -->
      <template slot="assetType" slot-scope="scope">
          <base-select
          v-model="scope.row.assetType"
          :options="assetsTypeOption"
          :selectedField="['dictId', 'dictName']"
          :disabled="true"
        ></base-select>
      </template>
      <template slot="isController" slot-scope="scope">
          <base-select
          v-model="scope.row.isController"
          style="width: 200px; margin-right: 10px"
          :options="isControllerOption"
          :selectedField="['key', 'label']"
          :disabled="true"
        ></base-select>
      </template>
      <!-- 是否已抵押 -->
      <template slot="mortgageStatus" slot-scope="scope">
          <base-select
          v-model="scope.row.mortgageStatus"

          :options="mortgageStatusOption"
          :selectedField="['dictId', 'dictName']"
          :disabled="true"
        ></base-select>
      </template>

    </base-table>
  </div>
</template>
<script>
import baseTable from '@/components/common/table/base-table/base-table.vue'
import BaseSelect from '@/components/common/base-select/base-select.vue'

import { assetsInfoTable } from '../utils/enterprise-info-config'
import { getDictLists } from '@/filters/fromDict'
export default {
  props: {
    tableData: Array
  },
  components: { baseTable, BaseSelect },
  data () {
    return {
      isControllerOption: [{ label: '是', key: '1' }, { label: '否', key: '0' }]
      // assetsInfoData: [] // 资产信息表格
    }
  },
  computed: {
    assetsInfolumn () {
      return assetsInfoTable(this)
    },
    assetsTypeOption () {
      return getDictLists('ASSET_TYPE')
    },
    mortgageStatusOption () {
      return getDictLists('HYPOTHECATE_STATE')
    },
    assetsInfoData: {
      get () {
        return this.tableData
      },
      set (val) {
        this.$emit('changeAssetsInfoData', val)
      }
    }
  },
  methods: {
    // 删除数据
    delAssetsItem (index) {
      this.assetsInfoData.splice(index, 1)
    },
    // 增加一条数据
    addAssetsInfo () {
      this.assetsInfoData.push({ add: true })
    }
  }
}
</script>
<style lang="scss" scoped>
.assetsInfo {
  padding: 10px;
}
.assestArea {
  margin-top: 20px;
}
.head {
  display: flex;
  justify-content: flex-end;
  // 页面按钮
  .addbutton {
    background: #4A7CF0;
    width: 20px;
    height: 20px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
    &:hover {
      background-color: #6996FF;
    }
  }
}
</style>
