//我司合作情况
<template>
  <div>
    <!-- 合作明细弹窗 -->
    <base-dialog :visible.sync="visibleState" :showFooter="false" title="合作明细">
    <div class="totalFinancing">融资总额（元）：<span>{{financeTotal}}</span></div>
      <base-table
        :columns="cooperationColumns"
        :showPage="false"
        :tableAttrs="{
          data: cooperationData,
          stripe: false,
          height: '550',
        }"
      :loadCount="loadCount"
        :dataSource.sync="cooperationData"
        :is-caculate-height="false"
        @pageIndexChange='pageIndexChange'
        @pageSizeChange='pageSizeChange'
      >
       <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
      </base-table>
      <template slot="footer">
        <base-button
          label="关 闭"
          type="default"
          @click="visibleState = false"
        ></base-button>
      </template>
    </base-dialog>
  </div>
</template>
<script>
import baseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import { cooperationTable } from '../utils/financing-info-config'
import { financeAdmittanceApi } from '@/api/financeAdmittanceApi'
import Storage from '@/utils/storage'

export default {
  props: {
    visible: Boolean
  },
  components: { baseDialog, BaseButton, BaseTable },
  data () {
    return {
      financeTotal: '', // 融资总额
      cooperationData: [], // 表格数据
      loadCount: 0,
      queryParas: {
        pageSize: 10, // 每页显示的数据
        pageIndex: 1
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.loadCount++
    })
  },
  watch: {
    visible (val) {
      if (val) {
        this.getCooperationDetail()
      }
    }
  },
  computed: {
    cooperationColumns () {
      return cooperationTable(this)
    },
    visibleState: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    // 查合作明细初始化书
    getCooperationDetail () {
      console.log(Storage.getLocal('contractInfo'), '----')
      financeAdmittanceApi.getCooperationDetail({ gysId: Storage.getLocal('contractInfo').gysId }).then(res => {
        this.cooperationData = res.data?.cooperationDetailVOS
        this.financeTotal = res.data?.financeTotal.toLocaleString('en-US', { minimumFractionDigits: 2 })
      })
    },
    pageIndexChange (data) {
      this.queryParas.pageIndex = data
      this.loadCount++
    },
    pageSizeChange (data) {
      this.queryParas.pageSize = data
      this.loadCount++
    }
  }
}
</script>
<style lang="scss" scoped>
.font {
  color: red;
  padding-right: 4px;
}
.detailed {
  color: red;
  margin-left: 20px;
  text-decoration: underline;
  cursor: pointer;
}
.totalFinancing{
  padding: 10px;
}
</style>
