<template>
  <div>
    <div style="width: 100%; height: calc(100vh - 50px)">
      <SeeksRelationGraph
        ref="seeksRelationGraph"
        :options="userGraphOptions"
      />
    </div>
  </div>
</template>

<script>
import SeeksRelationGraph from 'relation-graph'
export default {
  props: {
    chartData: Object
  },
  name: 'SeeksRelationGraphDemo',
  components: { SeeksRelationGraph },
  data () {
    return {
      g_loading: true,
      userGraphOptions: {
        backgrounImage: '',
        backgrounImageNoRepeat: true,
        layouts: [
          {
            label: '手工',
            layoutName: 'fixed',
            layoutClassName: 'seeks-layout-fixed',
            defaultJunctionPoint: 'border',
            defaultNodeShape: 0,
            defaultLineShape: 1
          }
        ],
        defaultNodeBorderWidth: 0,
        defaultNodeShape: 1,
        allowShowMiniNameFilter: false,
        allowShowMiniToolBar: false,
        defaultJunctionPoint: 'lr',
        defaultLineShape: 2,
        disableZoom: false,
        hideNodeContentByZoom: true,
        disableDragNode: true,
        moveToCenterWhenResize: false
      }
    }
  },
  created () {},
  mounted () {
    // this.$nextTick(() => {
    //   console.log(this.chartData, 'this.chartData')
    //   this.setGraphData()
    // })
    setTimeout(() => {
      this.setGraphData()
    }, 1500)
  },
  methods: {
    setGraphData () {
      const orignData = JSON.parse(JSON.stringify(this.chartData))
      var _graphSetting = this.$refs.seeksRelationGraph.graphSetting
      this.$refs.seeksRelationGraph.graphSetting.defaultLineShape = 1
      // 手工设置节点的坐标
      const _center = {
        x: _graphSetting.viewSize.width / 2 - _graphSetting.canvasOffset.x,
        y: _graphSetting.viewSize.height / 2 - _graphSetting.canvasOffset.y
      }
      var graphData = {
        rootId: 'root',
        nodes: [],
        links: []
      }
      // 添加根节点和虚拟节点
      var rootNode = {
        id: graphData.rootId,
        name: orignData.entname,
        styleClass: 'c-g-center',
        color: '#A4C1FF',
        width: 250,
        height: 50,
        x: _center.x - 125,
        y: _center.y - 25
      }
      var invRootNode = {
        id: 'invRoot',
        name: '股东' + orignData.invs.length,
        styleClass: 'c-g-group-node',
        color: '#FFC5A6',
        width: 80,
        height: 50
      }
      var historyInvRootRootNode = {
        id: 'branchRoot',
        name: '历史股东' + orignData.historyInvRoot.length,
        styleClass: 'c-g-group-node',
        color: '#FFA1F8',
        width: 100,
        height: 50
      }
      var personRootNode = {
        id: 'personRoot',
        name: '高管',
        styleClass: 'c-g-group-node' + orignData.persons.length,
        color: '#B9FFA7',
        width: 100,
        height: 50
      }
      var asinvRootNode = {
        id: 'asinvRoot',
        name: '对外投资' + orignData.asInvs.length,
        styleClass: 'c-g-group-node',
        color: '#FFBEC1',
        width: 100,
        height: 50
      }

      var legalPersonnelRootNode = {
        id: 'legalPersonnelRoot',
        name: '法定代表人' + orignData.legalPersonne.length,
        styleClass: 'c-g-group-node',
        color: '#FFA1F8',
        width: 100,
        height: 50
      }
      var actualControllerRootNode = {
        id: 'actualController' + orignData.actualController.length,
        name: '实际控制人',
        styleClass: 'c-g-group-node',
        color: '#FFA1F8',
        width: 100,
        height: 50
      }
      var ultimateBeneficiaryRootNode = {
        id: 'ultimateBeneficiary' + orignData.ultimateBeneficiary.length,
        name: '最终受益人',
        styleClass: 'c-g-group-node',
        color: '#FFA1F8',
        width: 100,
        height: 50
      }
      invRootNode.x = _center.x - 200 - invRootNode.width
      invRootNode.y = _center.y - 230
      personRootNode.x = _center.x - 200 - personRootNode.width
      personRootNode.y = _center.y + 150
      legalPersonnelRootNode.x = _center.x + 200
      legalPersonnelRootNode.y = _center.y - 250
      actualControllerRootNode.x = _center.x + 200
      actualControllerRootNode.y = _center.y + 50
      asinvRootNode.x = _center.x + 200
      asinvRootNode.y = _center.y - 130
      historyInvRootRootNode.x = _center.x - 300
      historyInvRootRootNode.y = _center.y - 25
      ultimateBeneficiaryRootNode.x = _center.x + 200
      ultimateBeneficiaryRootNode.y = _center.y + 150
      // 添加节点数据到graphData
      graphData.nodes.push(rootNode)
      graphData.nodes.push(invRootNode)
      graphData.nodes.push(personRootNode)
      graphData.nodes.push(asinvRootNode)
      graphData.nodes.push(historyInvRootRootNode)
      graphData.nodes.push(legalPersonnelRootNode)
      graphData.nodes.push(actualControllerRootNode)
      graphData.nodes.push(ultimateBeneficiaryRootNode)
      // 添加根节点和虚拟节点之间的关系，并将关系数据放入graphData
      graphData.links.push({
        from: rootNode.id,
        to: invRootNode.id,
        styleClass: 'c-g-l-group',
        color: '#C7E9FF',
        lineShape: 2
      })
      graphData.links.push({
        from: rootNode.id,
        to: personRootNode.id,
        styleClass: 'c-g-l-group',
        color: '#C7E9FF',
        lineShape: 2
      })
      graphData.links.push({
        from: rootNode.id,
        to: asinvRootNode.id,
        styleClass: 'c-g-l-group',
        color: '#C7E9FF',
        lineShape: 2
      })
      graphData.links.push({
        from: rootNode.id,
        to: historyInvRootRootNode.id,
        styleClass: 'c-g-l-group',
        color: '#C7E9FF',
        lineShape: 2
      })
      graphData.links.push({
        from: rootNode.id,
        to: legalPersonnelRootNode.id,
        styleClass: 'c-g-l-group',
        color: '#C7E9FF',
        lineShape: 2
      })
      graphData.links.push({
        from: rootNode.id,
        to: actualControllerRootNode.id,
        styleClass: 'c-g-l-group',
        color: '#C7E9FF',
        lineShape: 2
      })
      graphData.links.push({
        from: rootNode.id,
        to: ultimateBeneficiaryRootNode.id,
        styleClass: 'c-g-l-group',
        color: '#C7E9FF',
        lineShape: 2
      })
      // 将股东加入虚拟节点'股东'
      orignData.invs.forEach((thisNode, _index) => {
        thisNode.width = 200
        thisNode.x = invRootNode.x - 200 - thisNode.width
        thisNode.y = invRootNode.y + _index * 30
        graphData.nodes.push(thisNode)
        graphData.links.push({
          from: invRootNode.id,
          to: thisNode.id,
          text: thisNode.desc,
          color: '#FFC5A6',
          arrow: 'none',
          lineShape: 4
        })
      })
      // 将高管加入虚拟节点'高管'
      orignData.persons.forEach((thisNode, _index) => {
        thisNode.width = 200
        thisNode.x = personRootNode.x - 200 - thisNode.width
        thisNode.y = personRootNode.y + _index * 30
        graphData.nodes.push(thisNode)
        graphData.links.push({
          from: personRootNode.id,
          to: thisNode.id,
          text: thisNode.desc,
          color: '#B9FFA7',
          arrow: 'none',
          lineShape: 4
        })
      })
      // 将对外投资企业加入虚拟节点'对外投资'
      orignData.asInvs.forEach((thisNode, _index) => {
        thisNode.x = asinvRootNode.x + 250
        thisNode.y = asinvRootNode.y + _index * 30 - 17
        graphData.nodes.push(thisNode)
        graphData.links.push({
          from: asinvRootNode.id,
          to: thisNode.id,
          text: thisNode.desc,
          color: '#FFBEC1',
          lineShape: 4
        })
      })
      // 将分支机构加入虚拟节点'历史股东'
      orignData.historyInvRoot.forEach((thisNode, _index) => {
        thisNode.x = historyInvRootRootNode.x - 400
        thisNode.y = historyInvRootRootNode.y + _index * 30
        graphData.nodes.push(thisNode)
        graphData.links.push({
          from: historyInvRootRootNode.id,
          to: thisNode.id,
          text: thisNode.desc,
          color: '#FFA1F8',
          lineShape: 4
        })
      })
      // 法人代表
      orignData.legalPersonne.forEach((thisNode, _index) => {
        thisNode.x = legalPersonnelRootNode.x + 250
        thisNode.y = legalPersonnelRootNode.y + _index * 30 + 17
        graphData.nodes.push(thisNode)
        graphData.links.push({
          from: legalPersonnelRootNode.id,
          to: thisNode.id,
          text: thisNode.desc,
          color: '#FFA1F8',
          lineShape: 4
        })
      })
      // 最总受益人
      orignData.actualController.forEach((thisNode, _index) => {
        thisNode.x = actualControllerRootNode.x + 250
        thisNode.y = actualControllerRootNode.y + _index * 30 + 17
        graphData.nodes.push(thisNode)
        graphData.links.push({
          from: actualControllerRootNode.id,
          to: thisNode.id,
          text: thisNode.desc,
          color: '#FFA1F8',
          lineShape: 4
        })
      })
      // 最总受益人
      orignData.ultimateBeneficiary.forEach((thisNode, _index) => {
        thisNode.x = ultimateBeneficiaryRootNode.x + 250
        thisNode.y = ultimateBeneficiaryRootNode.y + _index * 30 + 17
        graphData.nodes.push(thisNode)
        graphData.links.push({
          from: ultimateBeneficiaryRootNode.id,
          to: thisNode.id,
          text: thisNode.desc,
          color: '#FFA1F8',
          lineShape: 4
        })
      })
      this.$refs.seeksRelationGraph.setJsonData(graphData, seeksRGGraph => { this.$refs.seeksRelationGraph.refresh() })
    }
  }
}
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped></style>
