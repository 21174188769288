//外部地产情况
<template>
  <div class="assetsInfo">

    <base-table
      :columns="propertyInfocolumn"
      :tableAttrs="{
        data: propertyInfoData,
        stripe: true,
      }"
      :isCaculateHeight="false"
      :showPage="false"
    >
      <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
      <!-- <template slot="nameHeader">
        <font style="color: #ee1c1c">*</font>名称
      </template>
       <template slot="constructionProjectsNumberHeader">
        <font style="color: #ee1c1c">*</font>在建项目数（个）
      </template>
       <template slot="totalContractAmountHeader">
        <font style="color: #ee1c1c">*</font>签约总额（元）
      </template>
       <template slot="totalReceivablesSettlementsHeader">
        <font style="color: #ee1c1c">*</font>应收结算款总额（元）
      </template>
       <template slot="businessProportionHeader">
        <font style="color: #ee1c1c">*</font>业务占比（%）
      </template>
      <template slot="paymentTypeHeader">
        <font style="color: #ee1c1c">*</font>付款方式
      </template>
      <template slot="paymentCycleHeader">
        <font style="color: #ee1c1c">*</font>付款周期
      </template> -->

       <template slot="paymentType" slot-scope="scope">
         <base-select
          v-model="scope.row.paymentType"

          :options="paymentTypeOption"
          :selectedField="['dictId', 'dictName']"
          :disabled="true"
        ></base-select>

      </template>

    </base-table>
  </div>
</template>
<script>
import baseTable from '@/components/common/table/base-table/base-table.vue'
import { outPropertyTable } from '../utils/enterprise-info-config'
import { getDictLists } from '@/filters/fromDict'
import BaseSelect from '@/components/common/base-select/base-select.vue'

export default {
  props: {
    tableData: Array
  },
  components: { baseTable, BaseSelect },
  data () {
    return {
      // propertyInfoData: [] // 资产信息表格
    }
  },
  computed: {
    propertyInfocolumn () {
      return outPropertyTable(this)
    },
    paymentTypeOption () {
      return getDictLists('PAYMENT_TYPE')
    },
    propertyInfoData: {
      get () {
        return this.tableData
      },
      set (val) {
        this.$emit('changePropertyInfoData', val)
      }
    }
  },
  methods: {
    // 删除数据
    delAssetsItem (index) {
      this.propertyInfoData.splice(index, 1)
    },
    addPropertyInfo () {
      this.propertyInfoData.push({ add: true, businessId: this.$route.query.businessId })
    }
  }
}
</script>
<style lang="scss" scoped>
.assetsInfo {
  padding: 10px;
}
.assestArea {
  margin-top: 20px;
}
.head {
  display: flex;
  justify-content: flex-end;
  // 页面按钮
  .addbutton {
    background: #4A7CF0;
    width: 20px;
    height: 20px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
    &:hover {
      background-color: #6996FF;
    }
  }
}
</style>
