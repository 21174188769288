//诉讼信息
<template>
  <div class="litigationInfo">
    <div class="head">
      <div class="leftButton">
        <!-- <base-button label='同步天眼查' @click="getSynchronization"></base-button> -->
        <!-- <el-upload
          action=""
          :http-request="function (url) {
          return importFile(url);
        }"
          :show-file-list="false"
           accept=".xls,.xlsx"
         >
         <base-button label="导入"  />
        </el-upload> -->
      </div>

    </div>
    <base-table
      :columns="litigationInfocolumn"
      :tableAttrs="{
        data: litigationInfoData,
        stripe: true,
      }"
      :isCaculateHeight="false"
      :showPage="false"
    >
      <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
      <!-- 案号 -->

      <!-- 案由 -->

      <!-- 标的金额（元） -->

      <!-- 案件状态 -->

      <!-- 附件 -->
       <template slot="finFileInfos" slot-scope="scope">
        <!-- 文件列按钮组 -->
        <file-button-group
          v-for="(item, index) in scope.row.finFileInfos"
          :key="index"
          :item="item"
          :index="index"
          :scope="scope"
          :isSign="scope.row.isSign"
          @previewShow="openPreview"
          @Upload="Upload"
          @deleteTemplate="deleteTemplate"
          @dowload='dowloadFile'
        />
      </template>
       <!-- 备注 -->

    </base-table>
 <pre-view  :fileId="fileData.fileId" :fileType="fileData.fileType" :isOpen="true" :count="fileCount" />
  </div>
</template>
<script>
import PreView from '@/components/pre-view/pre-view.vue'
import baseTable from '@/components/common/table/base-table/base-table.vue'
import { litigationInfoTable } from '../utils/enterprise-info-config'
import FileButtonGroup from '../components/file-button-group.vue'
import { fileAPi, downAPi } from '@/api/fileApi'
import { downFile } from '@/utils/devUtils'
import { dueDiligenceApi } from '@/api/businessApi'
import { litigationApi } from '@/api/companyApi'
export default {
  props: {
    tableData: Array
  },
  components: { baseTable, FileButtonGroup, PreView },
  data () {
    return {
      fileCount: 0,
      fileData: {}
      // litigationInfoData: [], // 诉讼信息表格
    }
  },
  computed: {
    litigationInfocolumn () {
      return litigationInfoTable(this)
    },
    litigationInfoData: {
      get () {
        return this.tableData
      },
      set (val) {
        this.$emit('changeLitigationInfoData', val)
      }
    }
  },
  methods: {
    // 删除数据
    delItem (index) {
      this.litigationInfoData.splice(index, 1)
    },
    addLitigationInfo () {
      this.litigationInfoData.push({ add: true, businessId: this.$route.query.businessId })
    },
    // 同步天眼查
    getSynchronization () {
      const params = {}
      litigationApi.getSynchronization(params).then(res => {
      })
    },
    // 导入数据
    importFile (param) {
      const formData = new FormData()
      const arr = param.file.name.split('.')
      const suffix = (arr[arr.length - 1]).toLowerCase()
      const lite = [
        'xls', 'xlsx']
      const flag = lite.some((val) => {
        return suffix === val
      })
      if (!flag) {
        this.warning('请上传xls,xlsx格式文件')
        return
      }
      formData.append('file', param.file)
      dueDiligenceApi.litigationinfoImport(formData).then(res => {
        if (res.data) {
          res.data.forEach(item => {
            this.litigationInfoData.push(item)
          })
        }
      })
    },
    // 追加文件
    appendFile (row, index) {
      console.log(row, 'row')
      if (!row.finFileInfos) {
        this.$set(row, 'finFileInfos', [])
      }
      const data = row.finFileInfos.every(item => item.fileId !== '0')
      if (!data) {
        return this.warning('请先上传文件')
      }
      row.finFileInfos.push({ fileId: '0', fileName: '', addFile: true })
    },
    // 预览
    openPreview (data) {
      this.fileData = data
      const fileSuffix = data.fileName ? data.fileName.split('.') : []
      this.fileData.fileType = fileSuffix[fileSuffix.length - 1]
      console.log(data, '222')
      this.fileCount++
    },
    // 文件上传
    async Upload (param, row, index, childIndex) {
      const arr = param.file.name.split('.')
      const suffix = arr[arr.length - 1]
      const lite = ['doc', 'docx', 'xlsx', 'xls', 'pdf', 'png', 'jpg', 'jpeg', 'bmp']
      const flag = lite.some((val) => {
        return suffix === val
      })
      if (!flag) {
        this.warning('请上传doc,docx,xlsx,xls,pdf,png,jpg,jpeg,bmp格式文件')
        return
      }
      const res = await this.UploadFile(param)
      this.$set(this.litigationInfoData[index].finFileInfos[childIndex], 'fileName', res.fileName)
      this.$set(this.litigationInfoData[index].finFileInfos[childIndex], 'fileId', res.keyId)
    },
    // 上传方法
    async UploadFile (param) {
      const formData = new FormData()
      formData.append('file', param.file)
      formData.append('belongCode', 'TEMPLATE')
      const res = await fileAPi.file(formData).then((res) => {
        if (res.data) {
          return res.data
        }
      })
      return res
    },
    // 删除文件
    deleteTemplate (row, index, i) {
      console.log(row, i, 'del')
      this.litigationInfoData[index].finFileInfos.splice(i, 1)
    },
    // 文件下载
    dowloadFile (data) {
      const fileId = data.currentFileId || data.fileId
      if (fileId) {
        const params = { keyId: fileId }
        downAPi
          .download(params)
          .then((res) => {
            downFile(res)
          })
          .catch((e) => {
            this.error(e)
          })
      } else {
        this.error('抱歉暂无资源')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.litigationInfo {
  padding: 10px;
}
.assestArea {
  margin-top: 20px;
}
.head {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  // 页面按钮
  .addbutton {
    background: #4A7CF0;
    width: 20px;
    height: 20px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
    &:hover {
      background-color: #6996FF;
    }
  }
}
.leftButton{
  width: 200px;
  justify-content: space-between;
  display: flex;
  padding-bottom: 5px;
}
.areaTitle{
  display: flex;
  font-size: 16px;
  color:#505559;
  margin-bottom: 5px;
  align-items: center;
}
</style>
