import {
  formatDate
} from '@/utils/auth/common'
// 资产信息表格
export const assetsInfoTable = (content) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '50px'
    },
    {
      label: '资产类型',
      prop: 'assetType'
    },
    {
      label: '是否已抵押',
      prop: 'mortgageStatus'
    },
    {
      label: '权证号',
      prop: 'warrantNumber'
    },
    {
      label: '权属人',
      prop: 'owner'
    },
    {
      label: '是否实控个人资产',
      prop: 'isController',
      width: '210',
      HiddenOverflow: true,
      showOverflowTooltip: false
    },
    {
      label: '坐落/明细',
      prop: 'address'
    },
    {
      label: '评估价',
      prop: 'appraisalPrice',
      align: 'right'
    },
    {
      label: '贷款余额（元）',
      prop: 'loanBalance',
      formatter: row => {
        return (row.loanBalance ? row.loanBalance.toLocaleString('en-US', {
          minimumFractionDigits: 2
        }) : '0')
      },
      align: 'right'
    }

  ]
}
// 外部地产情况
export const outPropertyTable = (content) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '50px'
    },
    {
      label: '名称',
      prop: 'name'
    },
    {
      label: '在建项目数（个）',
      prop: 'constructionProjectsNumber'
    },
    {
      label: '签约总额（元）',
      prop: 'totalContractAmount',

      formatter: row => {
        return (row.totalContractAmount ? row.totalContractAmount.toLocaleString('en-US', {
          minimumFractionDigits: 2
        }) : '0')
      },
      align: 'right'
    },
    {
      label: '应收结算款总额（元）',
      prop: 'totalReceivablesSettlements',
      formatter: row => {
        return (row.totalReceivablesSettlements ? row.totalReceivablesSettlements.toLocaleString('en-US', {
          minimumFractionDigits: 2
        }) : '0')
      },
      align: 'right'
    },
    {
      label: '业务占比（%）',
      prop: 'businessProportion'
    },
    {
      label: '付款方式',
      prop: 'paymentType'

    },
    {
      label: '付款周期',
      prop: 'paymentCycle'
    },
    {
      label: '抵房款情况',
      prop: 'arrivalPayment'
    },
    {
      label: '负面舆情',
      prop: 'negativePublicOpinion'
    },
    {
      label: '备注',
      prop: 'remark'
    }

  ]
}

// 负债情况
export const liabilitiesInfoTable = (content) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '50px'
    },
    {
      label: '借款人',
      prop: 'borrower'
    },
    {
      label: '借款机构名称',
      prop: 'borrowingOrg',
      HiddenOverflow: true,
      showOverflowTooltip: false
    },
    {
      label: '是否实控人个人借款',
      prop: 'isController',
      HiddenOverflow: true,
      width: '210',
      showOverflowTooltip: false
    },
    {
      label: '合同金额（元）',
      prop: 'contractAmount',
      align: 'right'
    },
    {
      label: '贷款余额（元）',
      prop: 'loanBalance',
      formatter: row => {
        return (row.loanBalance ? row.loanBalance.toLocaleString('en-US', {
          minimumFractionDigits: 2
        }) : '0')
      },
      align: 'right'
    },
    {
      label: '到期日',
      prop: 'expiryDate',
      formatter: row => {
        return formatDate(row.expiryDate, 'YY-MM-DD')
      }
    },
    {
      label: '融资类型',
      prop: 'financingType'
    },
    {
      label: '担保措施',
      prop: 'guaranteeMeasures'
    },
    {
      label: '月供',
      prop: 'monthlyPayment',
      align: 'right'
    }
  ]
}
// 诉讼情况
export const litigationInfoTable = (content) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '50px'
    },
    {
      label: '案号',
      prop: 'caseNumber'
    },
    {
      label: '案由',
      prop: 'causeAction'
    },
    {
      label: '标的金额（元）',
      prop: 'targetAmount',
      formatter: row => {
        return (row.targetAmount ? row.targetAmount.toLocaleString('en-US', {
          minimumFractionDigits: 2
        }) : '0')
      }
    },
    {
      label: '案件状态',
      prop: 'caseStatus'
    },
    {
      label: '附件',
      prop: 'finFileInfos'
    },
    {
      label: '备注',
      prop: 'remark'
    }
  ]
}
