<template>
  <div>
    <base-dialog
      :visible.sync="visibleState"
      title="企业图谱"
      :showFooter="false"
    >
    <EnterpriseAtlas :chartData='orignData'/>
    <template slot="footer">
      <base-button label="关 闭" type="default" @click="visibleState = false" />
    </template></base-dialog>
  </div>
</template>

<script>
import EnterpriseAtlas from './enterprise-atlas.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import { dueDiligenceApi } from '@/api/businessApi'
import Storage from '@/utils/storage'

export default {
  components: { BaseDialog, BaseButton, EnterpriseAtlas },
  props: {
    visible: Boolean
  },
  data () {
    return {
      orignData: {
        entname: Storage.getLocal('contractInfo').gysCompanyName,
        invs: [], // 股东
        persons: [], // 高管
        asInvs: [], // 对外投资
        historyInvRoot: [], // 历史股东
        legalPersonne: [], // 法人
        actualController: [], // 实际控制人
        ultimateBeneficiary: [] // 最终受益人
      },
      data01: [
        {
          alias: '小兵影视',
          amount: 153,
          base: 'gd',
          business_scope: '广播电视节目制作（具体经营范围以《广播电视节目制作经营许可证》为准）;电影和影视节目制作;音像制品制作;电影和影视节目发行;录音制作;营业性文艺表演;演出经纪代理服务;网上新闻服务;网络游戏服务;网络音乐服务;网上电影服务;网上图片服务;网上动漫服务;网上读物服务;网上视频服务;舞台表演艺术指导服务;群众参与的文艺类演出、比赛等公益性文化活动的策划;室内手工制作娱乐服务;文化娱乐经纪人;体育经纪人;影视经纪代理服务;音像经纪代理服务;文学、艺（美）术经纪代理服务;美术展览经纪代理服务;动漫（动画）经纪代理服务;软件开发;信息系统集成服务;计算机房维护服务;信息技术咨询服务;数据处理和存储服务;数字动漫制作;游戏软件设计制作;广告业;动漫及衍生产品设计服务;',
          category: '新闻和出版业',
          creditCode: '91440101MA59EPT46Q',
          estiblishTime: 1473609600000,
          id: 2943713148,
          legalPersonName: '王桦林',
          name: '广州小兵影视传媒有限责任公司',
          orgType: '其他有限责任公司',
          percent: '51%',
          personType: '1',
          regCapital: '300万人民币',
          regStatus: '开业',
          type: 1
        },
        {
          alias: '佳弘物业',
          amount: 11,
          base: 'gd',
          business_scope: '办公设备租赁服务;住房租赁;非居住房地产租赁;土地使用权租赁;物业管理;停车场服务;柜台、摊位出租;餐饮管理;集贸市场管理服务;',
          category: '房地产业',
          creditCode: '914401136986969500',
          estiblishTime: 1262620800000,
          id: 1574566577,
          legalPersonName: '张远辉',
          name: '广州市佳弘物业租赁有限公司',
          orgType: '有限责任公司(外商投资企业与内资合资)',
          percent: '23%',
          personType: '1',
          regCapital: '50万人民币',
          regStatus: '开业',
          type: 1
        }
      ],
      data02: [
        {
          chainList: [
            [
              {
                info: '',
                type: 'percent',
                value: '70%'
              },
              {
                cid: '1344542917',
                id: '2064234188',
                info: '',
                type: 'human',
                value: '王一波'
              },
              {
                info: '',
                type: 'percent',
                value: '70%'
              },
              {
                cid: '1344542917',
                info: '',
                type: 'company',
                value: '广州虹猫蓝兔动漫科技有限公司'
              }
            ]
          ],
          cid: '1344542917',
          id: '2064234188',
          logo: '',
          name: '王一波',
          type: 'human'
        },
        {
          chainList: [
            [
              {
                info: '',
                type: 'percent',
                value: '30%'
              },
              {
                cid: '1344542917',
                id: '2019859074',
                info: '',
                type: 'human',
                value: '梁少凤'
              },
              {
                info: '',
                type: 'percent',
                value: '30%'
              },
              {
                cid: '1344542917',
                info: '',
                type: 'company',
                value: '广州虹猫蓝兔动漫科技有限公司'
              }
            ]
          ],
          cid: '1344542917',
          id: '2019859074',
          logo: '',
          name: '梁少凤',
          type: 'human'
        }
      ],
      data03: [
        {
          regStatus: '开业',
          chainList: [
            {
              child: [
                {
                  type: 'percent',
                  value: '51%'
                },
                {
                  type: 'company',
                  value: '广州虹猫蓝兔动漫科技有限公司',
                  cid: '1344542917'
                },
                {
                  type: 'percent',
                  value: '51%'
                },
                {
                  type: 'company',
                  value: '广州小兵影视传媒有限责任公司',
                  cid: '2943713148'
                }
              ]
            }
          ],
          estiblishTime: 1473609600000,
          regCapital: '300.000000万人民币',
          name: '广州小兵影视传媒有限责任公司',
          alias: '小兵影视',
          logo: 'https://img5.tianyancha.com/logo/lll/71e1173f192e18845290efd7af9693b7.png@!f_200x200',
          percent: '51%',
          cid: '2943713148',
          legalPersonName: '王桦林'
        }
      ]

    }
  },
  // 计算属性 类似于data概念
  computed: {
    visibleState: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  // 监控data中的数据变化
  watch: {
    visibleState (val) {
      if (val) {
        this.getAtlasData()
      }
    }
  },
  // 方法集合
  methods: {
    // 天眼查，企业实际控制权
    getAtlasData () {
      // let newData01 = []
      // newData01 = this.data01.map((item, index) => {
      //   return { id: item.creditCode, text: item.name + '➠' + '认缴金额： ' + item.regCapital, desc: item.percent }
      // })
      // this.$set(this.orignData, 'asInvs', newData01)

      // let newData02 = []
      // newData02 = this.data02.map((item, index) => {
      //   let text = ''
      //   let desc = ''
      //   item.chainList[0].forEach((ele, index) => {
      //     if (ele.type === 'human') {
      //       text = ele.value
      //     } else if (ele.type === 'percent') {
      //       desc = ele.value
      //     }
      //   })
      //   return { id: item.id, text: text, desc: desc }
      // })
      // console.log(newData02, 'newData02')
      // this.$set(this.orignData, 'invs', newData02)

      // let newData03 = ''
      // newData03 = [{ id: this.data03[0].cid, text: this.data03[0].legalPersonName, desc: this.data03[0].percent }]
      // this.$set(this.orignData, 'legalPersonne', newData03)
      const params = { name: Storage.getLocal('contractInfo').gysCompanyName }
      dueDiligenceApi.imbusinessriskTycDwtz(params).then(res => {
        this.orignData.asInvs = res.data.map((item, index) => {
          return { id: item.creditCode, text: item.name + '➜' + '认缴金额 ' + item.regCapital, desc: item.percent }
        })
      })
      dueDiligenceApi.interfacesZzsyr(params).then(res => {
        Storage.setLocal('interfacesInfoZzsyr', res.data)
        this.orignData.invs = res.data.map((item, index) => {
          let text = ''
          let desc = ''
          item.chainList[0].forEach((ele, index) => {
            if (ele.type === 'human') {
              text = ele.value
            } else if (ele.type === 'percent') {
              desc = ele.value
            }
          })
          return { id: item.id, text: text, desc: desc }
        })
      })
      dueDiligenceApi.interfacesActualcontrolinfo(params).then(res => {
        Storage.setLocal('interfacesInfoControl', res.data)
        this.orignData.legalPersonne = [{ id: res.data[0].cid, text: res.data[0].legalPersonName, desc: res.data[0].percent }]
      })
      // await  supplierApi.tyceas({supplierName: this.value,operatorId: 1}).then(res=>{

      // })
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {},
  beforeCreate () {}, // 生命周期 - 创建之前
  beforeMount () {}, // 生命周期 - 挂载之前
  beforeUpdate () {}, // 生命周期 - 更新之前
  updated () {}, // 生命周期 - 更新之后
  beforeDestroy () {}, // 生命周期 - 销毁之前
  destroyed () {}, // 生命周期 - 销毁完成
  activated () {} // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="scss" scoped></style>
