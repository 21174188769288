// 担保动态
<template>
  <div class="guaranteeState">

    <base-table
      :columns="GuaranteeStateColumn"
      :tableAttrs="{
        data: newGuaranteeStateData,
        stripe: true,
      }"
      :isCaculateHeight="false"
      :showPage="false"
    >
      <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>

    </base-table>
  </div>
</template>
<script>
import baseTable from '@/components/common/table/base-table/base-table.vue'

import { GuaranteeStateTable } from '../utils/financing-info-config'
import { dueDiligenceApi } from '@/api/businessApi'

export default {
  props: {
    guaranteeStateData: Array
  },
  components: { baseTable },
  data () {
    return {
      // newGuaranteeStateData: [] // 表格数据
    }
  },
  computed: {
    GuaranteeStateColumn () {
      return GuaranteeStateTable(this)
    },
    newGuaranteeStateData: {
      get () {
        return this.guaranteeStateData
      },
      set (val) {
        this.$emit('changeGuaranteeState', val)
      }
    }
  },
  watch: {

  },
  methods: {

    // 增加信息
    addInfo () {
      this.newGuaranteeStateData.push({ add: true })
    },
    // 删除担保信息
    del (data, index) {
      // 如果是新增地直接删除了
      if (data.add) {
        this.newGuaranteeStateData.splice(index, 1)
        return
      }
      dueDiligenceApi.delGuarantee({ keyId: data.dueDiligenceId }).then(res => {
        if (res.data) {
          this.newGuaranteeStateData.splice(index, 1)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.guaranteeState {
  padding: 10px;

}
.add {
    height: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .addbutton {
      margin-left: 20px;
      background: #4A7CF0;
      width: 20px;
      height: 20px;
      color: #fff;
      text-align: center;
      line-height: 20px;
      cursor: pointer;
      &:hover {
        background-color: #6996FF;
      }
    }
  }
</style>
