//融资信息
<template>
  <div class="financingInfo" :id="scrollBoxId">
        <div data-title="融资方案" class="modelBox">
        <text-title index='01' label="融资方案"></text-title>
        <div>
        <form-info :count="count" :componentList="financingSchemeFrom"  :model="inFinancingData.financingPlanData" :showbor="false"/>
        </div>
      </div>
      <div data-title="担保信息" class="modelBox">
        <text-title index='02' label="担保信息"></text-title>
         <div class="guarantee">
          <personal-guarantee ref="personalGuarantee"></personal-guarantee>
          <enterprise-guarantee ref="enterpriseGuarantee"></enterprise-guarantee>
         </div>
      </div>
      <div data-title="旧贷情况" class="modelBox">
        <text-title index='03' label="旧贷情况"><template v-slot:left><div class="detailed" @click="cooperationVisible=true">查看合作明细</div></template></text-title>
        <!-- 我司合作信息弹窗 -->
        <cooperation-info :visible.sync="cooperationVisible"></cooperation-info>
        <div class="textarea">
          <el-input type="textarea" placeholder="请输入" :disabled='true' v-model="inFinancingData.examineDetails.oldLoanDetail" maxlength="200" show-word-limit rows='5'></el-input>
        </div>
      </div>
      <div data-title="但保动态" class="modelBox">
        <text-title index='04' label="担保动态"> </text-title>
        <guarantee-state :guaranteeStateData='inFinancingData.guaranteeStateData'></guarantee-state>
      </div>
      <div data-title="供应商基本面" class="modelBox">
        <text-title index='05' label="供应商基本面"><template v-slot:right><base-button label='企业图谱' @click.prevent="enterpriseVisible=true"></base-button></template></text-title>
        <!-- 企业介绍 -->
    <div class="module">
      <div class="head">

         <title-center :rules="false" name='企业介绍' />
      </div>
      <el-input
        type="textarea"
        :placeholder='placeholder01'
        v-model="inFinancingData.examineDetails.companyProfileVO.companyProfile"
        maxlength="200"
        show-word-limit
        rows="5"
        :disabled='true'
        v-if="inFinancingData.examineDetails.companyProfileVO.companyProfile"
      ></el-input><span style='padding:10px' v-else>--</span>
        <enclosure-upload  :isHiddenBtn='true' :fileInfos='inFinancingData.examineDetails.companyProfileVO.fileInfos'></enclosure-upload>

    </div>
    <!-- 实际控制人访谈 -->
    <div class="module">
      <div class="head">

          <title-center :rules="false" name='实际控制人访谈' />
      </div>
       <el-input
        type="textarea"
        placeholder="请输入"
        v-model="inFinancingData.examineDetails.actualControllerInterviewVO.actualControllerInterview"
        maxlength="200"
        show-word-limit
        rows="5"
        :disabled='true'
        v-if="inFinancingData.examineDetails.actualControllerInterviewVO.actualControllerInterview"
      ></el-input><span style='padding:10px' v-else>--</span>
        <enclosure-upload :isHiddenBtn='true' :fileInfos='inFinancingData.examineDetails.actualControllerInterviewVO.fileInfos'></enclosure-upload>

    </div>
    <!-- 业务情况 -->
    <div class="module">
      <div class="head">

          <title-center :rules="false" name='业务情况' />
      </div>
       <el-input
        type="textarea"
        placeholder="请输入"
        v-model="inFinancingData.examineDetails.businessStateVO.businessState"
        maxlength="200"
        show-word-limit
        rows="5"
        :disabled='true'
         v-if="inFinancingData.examineDetails.businessStateVO.businessState"
      ></el-input><span style='padding:10px' v-else>--</span>
        <enclosure-upload :isHiddenBtn='true' :fileInfos='inFinancingData.examineDetails.businessStateVO.fileInfos'></enclosure-upload>

    </div>
    <!-- 公路一合作情况 -->
    <div class="module">
      <div class="head">
        <title-center :rules="false" name='公路一合作情况' />
      </div>
       <el-input
        type="textarea"
        placeholder="请输入"
        v-model="inFinancingData.examineDetails.gemdaleCooperationVO.gemdaleCooperation"
        maxlength="200"
        show-word-limit
        rows="5"
        :disabled='true'
        v-if="inFinancingData.examineDetails.gemdaleCooperationVO.gemdaleCooperation"
      ></el-input><span style='padding:10px' v-else>--</span>
        <enclosure-upload :isHiddenBtn='true' :fileInfos='inFinancingData.examineDetails.gemdaleCooperationVO.fileInfos'></enclosure-upload>

    </div>
    <!-- 市场评价 -->
    <div class="module">
      <div class="head">

      <title-center :rules="false" name='市场评价' />
    </div>
     <el-input
        type="textarea"
        :placeholder='placeholder04'
        v-model="inFinancingData.examineDetails.marketEvaluationVO.marketEvaluation"
        maxlength="200"
        show-word-limit
        rows="5"
        :disabled='true'
        v-if="inFinancingData.examineDetails.marketEvaluationVO.marketEvaluation"
      ></el-input>
      <span style='padding:10px' v-else>--</span>
      <enclosure-upload :isHiddenBtn='true' :fileInfos='inFinancingData.examineDetails.marketEvaluationVO.fileInfos'></enclosure-upload>

    </div>
        </div>
      <div data-title="尽调情况" class="modelBox">
        <text-title index='06' label="尽调情况"/>
        <investigation-situation ref="investigation" :investigationSituationData='inFinancingData.investigationSituationData'></investigation-situation>
      </div>
   <scroll-fool ref="scrollFool" :scrollData="scrollData" :scrollBoxId='scrollBoxId'/>
    <!-- 企业图谱 -->
    <enterprise-atlas-dialog  :visible.sync="enterpriseVisible" />
  </div>
</template>
<script>
import FormInfo from '@/components/packages/form-info/form-info-new.vue'
import EnterpriseAtlasDialog from '../components/enterprise-atlas-dialog.vue'
import TitleCenter from '@/pages/business/components/title-center.vue'
import TextTitle from '@/components/packages/text-title/text-title.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import CooperationInfo from '../components/cooperation-info.vue'
import GuaranteeState from '../components/guarantee-state.vue'
import InvestigationSituation from '../components/investigation-situation.vue'
import { dueDiligenceApi } from '@/api/businessApi'
import EnterpriseGuarantee from '../components/enterprise-guarantee.vue'
import PersonalGuarantee from '../components/personal-guarantee.vue'
import EnclosureUpload from '../components/enclosure-upload.vue'
import { financingSchemeFrom } from '../utils/financing-info-config'
import ScrollFool from '@/components/packages/scroll-fool/scroll-fool.vue'
export default {
  components: {
    EnterpriseAtlasDialog,
    TextTitle,
    CooperationInfo,
    GuaranteeState,
    BaseButton,
    InvestigationSituation,
    EnterpriseGuarantee,
    PersonalGuarantee,
    EnclosureUpload,
    ScrollFool,
    TitleCenter,
    FormInfo
  },
  props: {
    tabsTitles: Array, // 标题错误状态
    showMain: Number, // 当前显示页面
    financingData: Object // 融资方案信息
  },
  data () {
    return {
      count: 0,
      showPage: 0,
      enterpriseVisible: false,
      placeholder01: '示例：' + '\n' + '企业名称+法人姓名+成立时间+注册资本+实收资本',
      placeholder04: '1.同行业评价（例如：公司名称-职称-评价内容）' + '\n' + '2.上下游评价（例如：公司名称-职称-评价内容）' + '\n' + '3.市场获奖',
      companyProfile: '', // 企业介绍
      actualControllerInterview: '', // 实际控制人访谈
      gemdaleCooperation: '', // 公路一合作情况
      marketEvaluation: '', // 市场评价
      businessId: '',
      cooperationVisible: false, // 合作情况弹窗
      oldLoanDetail: '', // 旧贷内容
      financingBaseInfo: {}, // 基本面信息
      savePassArr: [],
      scrollBoxId: 'finBox',
      scrollData: [
        { label: '融资方案', id: 0 },
        { label: '担保信息', id: 1 },
        { label: '旧贷情况', id: 2 },
        { label: '但保动态', id: 3 },
        { label: '供应商基本面', id: 4 },
        { label: '尽调情况', id: 5 }
      ]
    }
  },
  created () {
    this.businessId = this.$route.query.businessId
  },
  mounted () {
    this.count++
    console.log(this.inFinancingData, '所有数据')
  },
  computed: {
    financingSchemeFrom () {
      return financingSchemeFrom(this)
    },
    // 外部数据传入
    inFinancingData: {

      get () {
        return this.financingData
      },
      set (val) {
        this.$emit('changeFinancingData', val)
      }
    }
  },
  watch: {
    showMain (val) {
      if (val === 0) {
        this.count++
      }
    }
  },
  methods: {
    newsubmit () {
      console.log(this.inFinancingData.guaranteeStateData, 'guaranteeStateData')
      console.log(this.inFinancingData.investigationSituationData, 'investigationSituationData')
      console.log(this.inFinancingData.examineDetails, 'examineDetails')
      console.log(this.$refs.personalGuarantee.personalData, 'personalGuarantee')
      console.log(this.$refs.enterpriseGuarantee.enterpriseData, 'enterpriseData')
    },
    // 融资方案 选择还款方式
    submit (data) {
      this.$set(this.inFinancingData.financingPlanData, 'finRepayPlans', data)
    },

    // 修改融资期限单位
    changeValidTimeUnit (val) {
      this.$set(this.inFinancingData.financingPlanData, 'financeTermUnit', val)
    },
    // 保存 & 提交 所有信息
    async  saveAll (type) {
      this.savePassArr = []
      await this.saveFinPlan(type)
      await this.guaranteeBatchSave(type)
      await this.detailBatchSave(type)
      if (type === 'submit') {
        this.guaranteeValid()
      }
      const tip = this.savePassArr.find(item => item.isPass === false)
      if (tip) {
        if (this.showMain === this.showPage) {
          this.warning(tip.warningTip)
          this.$refs.scrollFool.touchactive(tip.module)
        }
      } else {
        this.$emit('errMsg', this.showPage, true)
        console.log('提交成功，显示√')
      }
      return tip
    },

    // 尽调详情表格保存
    detailBatchSave (type) {
      const data = { businessId: this.$route.query.businessId, data: this.inFinancingData.investigationSituationData }
      if (type === 'save') {
        dueDiligenceApi.detailBatchSave(data).then(res => {

        })
      } else if (type === 'submit') {
        let pass = true
        if (data.data.length > 0) {
          data.data.forEach(item => {
            console.log(!item.visitDate || !item.visitAddress || !item.visitCompany || !item.visitPersonnel || !item.finFileInfos || !item.finFileInfos[0].fileName, '88888')
            if (!item.visitDate || !item.visitAddress || !item.visitCompany || !item.visitPersonnel || !item.finFileInfos || !item.finFileInfos[0].fileName) {
              pass = false
            }
          })
        } else {
          pass = false
        }
        if (pass) {
          dueDiligenceApi.detailBatchSubmit(data).then(res => {})
        } else {
          this.savePassArr.push({ isPass: false, warningTip: '完善尽调详情表格', module: 5 })
        }
      }
    },
    // 保存担保动态信息
    guaranteeBatchSave () {
      const data = { businessId: this.$route.query.businessId, data: this.inFinancingData.guaranteeStateData }

      dueDiligenceApi.guaranteeBatchSave(data).then((res) => {
        if (res.data) {
        }
      })
    },
    // 校验非表单内容
    guaranteeValid () {
      // 个人担保
      if (this.$refs.personalGuarantee.personalData.length <= 0) {
        this.savePassArr.push({ isPass: false, warningTip: '请完善个人担保信息', module: 1 })
        this.$emit('errMsg', this.showPage, false)
      }
      // 企业担保
      if (this.$refs.enterpriseGuarantee.enterpriseData.length <= 0) {
        this.savePassArr.push({ isPass: false, warningTip: '请完善企业担保信息', module: 1 })
        this.$emit('errMsg', this.showPage, false)
      }
      // 供应商基本面各项信息
      if (!this.inFinancingData.examineDetails.companyProfileVO.companyProfile) {
        this.savePassArr.push({ isPass: false, warningTip: '请完善供应商基本面企业介绍', module: 4 })
        this.$emit('errMsg', this.showPage, false)
      }
      if (!this.inFinancingData.examineDetails.actualControllerInterviewVO.actualControllerInterview) {
        this.savePassArr.push({ isPass: false, warningTip: '请完善供应商基本面实际控制人访谈', module: 4 })
        this.$emit('errMsg', this.showPage, false)
      }
      if (!this.inFinancingData.examineDetails.businessStateVO.businessState) {
        this.savePassArr.push({ isPass: false, warningTip: '请完善供应商基本面业务情况', module: 4 })
        this.$emit('errMsg', this.showPage, false)
      }
      if (!this.inFinancingData.examineDetails.gemdaleCooperationVO.gemdaleCooperation) {
        this.savePassArr.push({ isPass: false, warningTip: '请完善供应商基本面公路一合作情况', module: 4 })
        this.$emit('errMsg', this.showPage, false)
      }
      if (!this.inFinancingData.examineDetails.marketEvaluationVO.marketEvaluation) {
        this.savePassArr.push({ isPass: false, warningTip: '请完善供应商基本面市场评价', module: 4 })
        this.$emit('errMsg', this.showPage, false)
      }
    },

    // 保存&提交  融资方案基本信息
    saveFinPlan (type) {
      this.inFinancingData.financingPlanData.businessId = this.businessId
      if (type === 'save') {
        dueDiligenceApi.draftSaveFinPlan(this.inFinancingData.financingPlanData).then(res => {
          if (res.data) {
            console.log('保存成功')
          }
        })
      } else if (type === 'submit') {
        this.$refs.formDataList.validate(valid => {
          if (valid) {
            dueDiligenceApi.saveFinPlan(this.inFinancingData.financingPlanData).then(res => {
              if (res.data) {
                this.$emit('errMsg', this.showPage, true)
                console.log('提交成功')
              }
            })
          } else {
            this.savePassArr.push({ isPass: false, warningTip: '完善融资方案表单', module: 0 })
            this.$emit('errMsg', this.showPage, false)
            return false
          }
        })
      }
    }

  }
}
</script>
<style lang="scss" scoped>
.financingInfo {
   height: 100%;
  background: #fff;
  overflow-y: auto;
  .mainContent {
    background: #fff;
    // height: calc(100% - 105px);
    // overflow-y: scroll;
  }
  .footer {
    text-align: center;
    margin: 0px 0 10px;
    padding: 14px 0;
    box-shadow: 0px 0px 13px 2px rgba(#4A7CF0, 0.2);
    background: #ffffff;
  }
}
.detailed {
  font-size: 16px;
  color: #2862E7;
  padding-left: 10px;
  line-height: 30px;
  text-decoration: underline;
  cursor: pointer;
}
.textarea {
  padding: 10px;
}
.guarantee {
  background-color: #ffffff;
}

.module{
  margin-top: 20px;
  .head {
    height: 30px;
    padding:0 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .el-textarea{
    padding: 10px;
    width: calc(100% - 20px);
  }
}
/deep/ .el-form-item__content {
    height: auto !important;
  }
</style>
